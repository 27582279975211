import {useState, useEffect} from 'react'
import {BrowserRouter as Router, Route, Navigate, useLocation, useNavigate} from 'react-router-dom'
import {
	IconButton,
	Box,
	Alert
} from '@mui/material'
import {Forest} from '@mui/icons-material'
import {useAuth} from 'providers/auth'
import {CenterBox} from 'components/CenterBox'

const ErrorBox = ({error}) => (
	<Box sx={{alignSelf: 'center'}}>
		<Alert severity='error'>{error}</Alert>
	</Box>
)

export default function LoginPage() {
	const auth = useAuth()
	const navigate = useNavigate()
	const [error, setError] = useState(auth?.authError || null)

	document.title = 'Login'

	useEffect(() => {
		if (auth?.user) {
			navigate('/')
		}
	}, [auth?.user])

	useEffect(() => {
		setError(auth?.authError || null)
	}, [auth])

	const handleSignInClick = x => {
		setError(null)
		auth.signUserIn(x)
	}

	return (
		<CenterBox  sx={{textAlign: 'center'}}>
			<IconButton
				variant='contained'
				color='primary'
				onClick={handleSignInClick}
				sx={{
					// height: '46px',
					// width: '46px'
				}}
			>
				<Forest fontSize='small' />
			</IconButton>
			{error && <ErrorBox error={error} />}
		</CenterBox>
	)
}