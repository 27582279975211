import {useState, useEffect, useCallback, useContext} from 'react'
import {theme} from 'providers/theme'
import {db} from 'providers/firebase'
import {
	ref,
	onValue,
	query,
	limitToLast,
	orderByChild
} from 'firebase/database'
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter'
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
import {atelierLakesideDark} from 'react-syntax-highlighter/dist/esm/styles/hljs'
import {capitalise, blank} from 'utils'
import {SourceContext} from 'providers/DataProvider'
import {
	DataGridPro as DataGrid,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from '@mui/x-data-grid-pro'

import {
	Box,
	Tooltip,
	Table,
	TableBody,
	TableCell,
	TableRow,
	useMediaQuery
} from '@mui/material'

import {
	Circle,
	ExpandLess,
	ExpandMore
} from '@mui/icons-material'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

SyntaxHighlighter.registerLanguage('json', json)


const renderBlank = () => blank


const RenderEntryDate = ({value}) => {
	const [now, setNow] = useState(dayjs())
	const [dateFormat, setDateFormat] = useState('dddd[,] Do MMMM [at] HH[:]m')
	const hoursDiff = now.diff(dayjs(value), 'hour')
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))

	useEffect(() => {
		const interval = setInterval(() => setNow(dayjs()), 1000)
		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		if (dayjs().isSame(dayjs(value), 'day')) {
			setDateFormat('HH[:]mm')
		} else {
			if (smallViewport) {
				if (dayjs().isSame(dayjs(value), 'year')) {
					setDateFormat('dd DD[/]MM HH[:]mm')
				} else {
					setDateFormat('dd DD[/]MM[/]YY HH[:]mm')
				}
			} else {
				if (dayjs().isSame(dayjs(value), 'year')) {
					setDateFormat('dddd[,] Do MMMM [at] HH[:]mm')
				} else {
					setDateFormat('dddd[,] Do MMMM YYYY [at] HH[:]mm')
				}
			}
		}
	}, [smallViewport])


	return (
		<Tooltip
			placement='top'
			title={dayjs(value).format('dddd[,] Do MMMM YYYY [at] HH[:]mm[:]ss')}
		>
			<span>
				{hoursDiff < 1 ? (
					dayjs(value).fromNow()
				) : (
					dayjs(value).format(dateFormat)
				)}
			</span>
		</Tooltip>
	)
}


const renderLevelIcon = ({value}) => {
	if (!value) {return blank}
	value = value.replace(/^(warn)$/, 'warning')
	return (
		<Tooltip
			arrow
			placement='top'
			title={capitalise(value)}
		>
			<Circle sx={{
				color: `${value}.main`,
				fontSize: '.75rem',
				verticalAlign: 'middle'
			}}/>
		</Tooltip>
	)
}


const renderLevelLine = ({value}) => {
	if (!value) {return blank}
	value = value.replace(/^(warn)$/, 'warning')
	return (
		<Box sx={{
			width: '6px',
			height: '100%',
			backgroundColor: theme.palette[value]?.main || '#000000'
		}} />
	)
}


const columnOptions = {
	filterable: true,
	hideable: true,
	hideSortIcons: true,
	sortable: false
}


const EntryDataTable = () => {
	const [entryData, setEntryData] = useState([])
	const [loading, setLoading] = useState(true)
	const sources = useContext(SourceContext)
	const [totalEntries, setTotalEntries] = useState(0)
	const smallViewport = useMediaQuery(theme.breakpoints.down('md'))
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5
	})

	useEffect(() => {
		onValue(
			query(ref(db, '/totalEntries')), snapshot => {
				setTotalEntries(snapshot.val())
			}
		)
	}, [])
	

	const getSourceName = slug => sources.find(source => source.slug === slug)?.name || slug

	const renderSource = ({row}) => {
		const {source} = row
		return (
			<Tooltip
				arrow
				placement='top'
				title={<>
					<Box>
						{(source.slug || '?')}
					</Box>
					{source.ips ? (
						<Box>
							{source.ips.join(',\n')}
						</Box>
					) : source.ip && (
						<Box>
							{source.ip}
						</Box>
					)}
				</>
			}>
				<span>{getSourceName(source.slug) || source.slug || 'unknown'}</span>
			</Tooltip>
		)
	}


	useEffect(() => {
		const entriesDataRef = ref(db, '/entries')
		setLoading(true)
		onValue(
			query(
				entriesDataRef,
				orderByChild('date'),
				limitToLast(paginationModel.pageSize * (paginationModel.page+1))
			), snapshot => {
				if (snapshot.val()) {
					const arr = Object.values(snapshot.val() || {})
					setEntryData(arr)
				}
				setLoading(false)
			}
		)
	}, [paginationModel])


	const handlePaginationModelChange = newPaginationModel => setPaginationModel(newPaginationModel)

	const columns = useCallback(() => ([{
		field: 'level',
		headerName: 'Level',
		renderHeader: renderBlank,
		minWidth: 24,
		maxWidth: 24,
		type: 'singleSelect',
		headerClassName: 'statusHeader',
		cellClassName: 'statusCell',
		valueOptions: [
			'info',
			'warn',
			'error'
		],
		...smallViewport ? {
			renderCell: renderLevelLine,
			minWidth: 6,
			maxWidth: 6,
			filterable: false,
			hideable: false,
			hideSortIcons: true,
			sortable: false
		} : {
			renderCell: renderLevelIcon,
			minWidth: 32,
			maxWidth: 32,
			...columnOptions
		}
	},{
		field: 'source',
		headerName: 'Source',
		flex: 1.5,
		valueGetter: params => params.row.source.slug,
		renderCell: renderSource,
		type: 'singleSelect',
		valueOptions: sources.map(source => ({
			value: source.slug,
			label: getSourceName(source.slug)
		})),
		...columnOptions
	},{
		field: 'date',
		headerName: 'Date',
		flex: smallViewport ? 1 : 1.5,
		renderCell: RenderEntryDate,
		...columnOptions
	},{
		field: 'message',
		headerName: 'Message',
		flex: 4,
		minWidth: 150,
		...columnOptions
	},{
		...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
		headerName: 'Expand'
	}]), [sources])


	const rows = useCallback(() => (
		entryData
		.flat()
		.reverse()
		.map((entry, i) => ({
			id: `entry-${i}`,
			date: entry.date,
			level: entry.level,
			message: entry.message,
			source: entry.source,
			data: entry.data,
		}))
	), [entryData])

	const getDetailPanelContent = useCallback(({row}) => {
		return row?.data ? (
			<Table size='small'>
				<TableBody>
					{Object.entries(row.data).map(([key, value]) => (
						<TableRow key={key}>
							<TableCell
								component='td'
								scope='row'
							>
								{decodeURIComponent(key).replace('%2E', '.')}
							</TableCell>
							<TableCell sx={{
								width: '99.9%',
								wordBreak: 'break-all'
							}}>
								{typeof value === 'object' ? (
									<SyntaxHighlighter
										language='json'
										style={atelierLakesideDark}
										wrapLongLines
									>
										{JSON.stringify(value, null, 2)}
									</SyntaxHighlighter>
								) : (
									value
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		) : null
	}, [entryData])

	const getDetailPanelHeight = useCallback(() => 'auto', [])

	return (
		<Box sx={{height: `calc(100vh - ${theme.spacing(2)})})`}}>
			<DataGrid
				density='compact'
				rows={rows()}
				columns={columns()}
				
				disableColumnPinning
				disableColumnReorder
				disableRowSelectionOnClick
				
				columnHeaderHeight={52}
				rowHeight={42}
				
				autoPageSize
				rowCount={totalEntries}
				onPaginationModelChange={handlePaginationModelChange}
				paginationModel={paginationModel}
				pagination
				loading={loading}

				getDetailPanelContent={getDetailPanelContent}
				getDetailPanelHeight={getDetailPanelHeight}
				components={{
					DetailPanelExpandIcon: ExpandMore,
					DetailPanelCollapseIcon: ExpandLess
				}}

				columnVisibilityModel={{
					source: !smallViewport
				}}
				
				sx={{
					'& > div > div:nth-of-type(3)': {
						display: 'none'
					},
					'& .MuiDataGrid-columnSeparator': {
						display: 'none',
					},
					'& .MuiDataGrid-footerContainer': {
						minHeight: 38,
						height: 38,
						borderTop: 'none'
					},
					'& .MuiDataGrid-virtualScroller': {
						overflowX: 'hidden'
					},
					'& .MuiTablePagination-actions .MuiButtonBase-root': {
						padding: .5,
						margin: .5
					},
					...smallViewport && {
						'& .MuiDataGrid-columnHeader.statusHeader': {
							marginRight: 1,
							paddingLeft: 0
						},
						'& .MuiDataGrid-columnHeader.statusHeader .MuiIconButton-root': {
							pl: 0
						},
						'& .MuiDataGrid-cell.statusCell': {
							padding: 0
						}
					}
				}}
			/>
		</Box>
	)
}

export default EntryDataTable