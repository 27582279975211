import {useContext, useState, useEffect} from 'react'
import {SourceContext} from 'providers/DataProvider'
import {db} from 'providers/firebase'
import {ref, update} from 'firebase/database'

import {
	Box,
	Paper,
	Typography,
	Stack,
	Grid,
	TextField,
	InputLabel,
	InputAdornment,
	IconButton,
	OutlinedInput,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	AccordionActions,
	Button
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'

import {
	Edit,
	EditOff,
	Visibility,
	VisibilityOff,
	ExpandMore,
	Casino
} from '@mui/icons-material'

import {capitalise} from 'utils.js'


const Item = ({children, title, typographyProps, ...props}) => (
	<Accordion disableGutters>
		<AccordionSummary
			expandIcon={<ExpandMore />}
		>
			<Typography {...typographyProps}>
				{title}
			</Typography>
		</AccordionSummary>
		<AccordionDetails>
			{children}
		</AccordionDetails>
	</Accordion>
)

const SourceTitle = ({children, ...props}) => <Typography variant='h6' marginBottom={2} {...props}>{children}</Typography>
const LabelGrid = ({children, ...props}) => <Grid item xs={3} {...props}>{children}</Grid>
const FieldGrid = ({children, ...props}) => <Grid item xs={9} {...props}>{children}</Grid>

const ResetButton = props => <Button {...props}>Reset</Button>

const SaveButton = ({source, values, children, saveCallback, ...props}) => {
	const [loading, setLoading] = useState(false)
	
	const handleClick = e => {
		setLoading(true)
		update(ref(db, `/sources/${source.slug}`), values).then(() => {
			saveCallback({
				status: 'success',
				values
			})
		}).catch(e => {
			saveCallback({
				status: 'error',
				error: e,
				values
			})
			console.error(e)
		}).finally(() => {
			setLoading(false)
		})
	}
	
	return (
		<LoadingButton
			onClick={handleClick}
			loading={loading}
			{...props}
		>
			{children}
		</LoadingButton>
	)
}

/**
 * new
 * container
	delete	modal/confirm	adds 'deleted: true' to source
								deleted means that the server no longer accepts entries from that key, and in the app, previous entries are greyed/italic whatever
	key		textfield	hidden, revealable, disabled
	name	textfield	editable
	slug	textfield	uneditable, unique
	submit	button		

add new
	key		textfield	generated, disabled
	name	textfield	editable
	slug	textfield	editable, unique (suggested: string.replace(/[^a-zA-Z0-9]g/,'').toLowerCase() )
	submit	button

entries/x/source needs to not have the name, only the slug, and the app should know the name from the slug
 */

const Source = ({source, ...props}) => {
	const [values, setValues] = useState({
		name: source.name || '',
		slug: source.slug || '',
		key: source.key || ''
	})
	const [changed, setChanged] = useState(false)
	const [initialValues, setInitialValues] = useState({...source})
	
	const [nameEditable, setNameEditable] = useState(false)
	const toggleNameEditable = () => setNameEditable(!nameEditable)

	const [showKey, setShowKey] = useState(false)
	const toggleShowKey = () => setShowKey(!showKey)

	const handleChange = prop => event => {
		setValues({...values, [prop]: event.target.value})
	}

	useEffect(() => setChanged(!Object.entries(initialValues).every(([key, value]) => initialValues[key] === values[key])), [values])

	const saveCallback = ({status, values}) => {
		if (status === 'success') {
			setNameEditable(false)
			setInitialValues(values)
			setChanged(false)
		} else {
			
		}
		
	}

	const resetValues = () => {
		setValues(initialValues)
		setNameEditable(false)
	}
	
	return (
		<Item title={source.name}>
			<Box
				component='form'
				autoComplete='off'
			>
				<Grid container spacing={1} alignItems='center'>

					<LabelGrid>
						<InputLabel
							htmlFor={`${source.slug}-name`}
							disabled={!nameEditable}
						>
							Name
						</InputLabel>
					</LabelGrid>
					<FieldGrid>
						<OutlinedInput
							id={`${source.slug}-name`}
							type='text'
							value={values.name}
							fullWidth
							size='small'
							disabled={!nameEditable}
							onChange={handleChange('name')}
							required
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										onClick={toggleNameEditable}
										edge='end'
									>
										{nameEditable ? <EditOff /> : <Edit />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FieldGrid>
				
					<LabelGrid>
						<InputLabel
							htmlFor={`${source.slug}-name`}
							disabled
						>
							Slug
						</InputLabel>
					</LabelGrid>
					<FieldGrid>
						<TextField
							id={`${source.slug}-slug`}
							fullWidth
							variant='outlined'
							size='small'
							disabled
							value={values.slug}
						/>
					</FieldGrid>
				
					<LabelGrid>
						<InputLabel
							htmlFor={`${source.slug}-key`}
							disabled={!showKey}
						>
							Key
						</InputLabel>
					</LabelGrid>
					<FieldGrid>
						<OutlinedInput
							id={`${source.slug}-key`}
							value={values.key}
							fullWidth
							size='small'
							disabled
							type={showKey ? 'text' : 'password'}
							endAdornment={<>
								<InputAdornment position='end'>
									<IconButton
										onClick={toggleShowKey}
										edge='end'
									>
										{showKey ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							</>}
						/>
					</FieldGrid>

				</Grid>

				{changed && 
					<AccordionActions sx={{mt:0, pb:0, pr: 0}}>
						<ResetButton onClick={resetValues}>
							Reset
						</ResetButton>
						<SaveButton
							source={source}
							values={values}
							saveCallback={saveCallback}
						>
							Save
						</SaveButton>
					</AccordionActions>
				}
			</Box>
		</Item>
	)
}

const NewSource = ({setAddingNew, ...props}) => {
	const makeUuid = () => window.crypto.randomUUID()
	const generateKey = () => setValues({...values, key: makeUuid()})
	const [submitStatus, setSubmitStatus] = useState(false)
	const [values, setValues] = useState({
		name: '',
		slug: '',
		key: makeUuid()
	})
	
	const handleChange = prop => event => {
		setValues({
			...values,
			...prop === 'name' ? {
				name: event.target.value,
				slug: event.target.value ? event.target.value.replaceAll(' ','-').toLowerCase() : ''
			} : {
				[prop]: event.target.value
			}
		})
	}

	
	
	const saveCallback = ({status, values}) => {
		setSubmitStatus(status)
		if (status === 'success') {
			setAddingNew(false)
		} else {

		}
	}


	// setSources({
	// 	...sources,
	// 	[Object.keys(sources).length]: {
	// 		key: uuid,
	// 		name: '',
	// 		slug: ''
	// 	}
	// })
	
	return (
		<Item
			title={values.name || 'New Source'}
			typographyProps={{
				sx: {
					fontStyle: values.name ? 'italic' : 'none',
				}
			}}
		>
			<Box
				component='form'
				autoComplete='off'
			>
				<Grid container spacing={1} alignItems='center'>
					<LabelGrid>
						<InputLabel
							htmlFor={`${values.slug}-name`}
						>
							Name
						</InputLabel>
					</LabelGrid>
					<FieldGrid>
						<OutlinedInput
							id={`${values.slug}-name`}
							type='text'
							value={values.name}
							fullWidth
							size='small'
							onChange={handleChange('name')}
							required
						/>
					</FieldGrid>
				
					<LabelGrid>
						<InputLabel
							htmlFor={`${values.slug}-name`}
						>
							Slug
						</InputLabel>
					</LabelGrid>
					<FieldGrid>
						<TextField
							id={`${values.slug}-slug`}
							fullWidth
							variant='outlined'
							size='small'
							onChange={handleChange('slug')}
							required
							value={values.slug}
						/>
					</FieldGrid>
				
					<LabelGrid>
						<InputLabel
							htmlFor={`${values.slug}-key`}
						>
							Key
						</InputLabel>
					</LabelGrid>
					<FieldGrid>
						<OutlinedInput
							id={`${values.slug}-key`}
							value={values.key}
							fullWidth
							size='small'
							disabled
							type='text'
							endAdornment={<>
								<InputAdornment position='end'>
									<IconButton
										onClick={generateKey}
										edge='end'
									>
										<Casino />
									</IconButton>
								</InputAdornment>
							</>}
						/>
					</FieldGrid>
				</Grid>

				<AccordionActions sx={{mt:0, pb:0, pr: 0}}>
					<SaveButton
						source={values}
						values={values}
						saveCallback={saveCallback}
						disabled={Boolean(submitStatus)}
						color={submitStatus || 'primary'}
					>
						{submitStatus ? capitalise(submitStatus) : 'Create'}
					</SaveButton>
				</AccordionActions>
			</Box>
		</Item>
	)
}


const Sources = () => {
	const sourceData = useContext(SourceContext)
	const [addingNew, setAddingNew] = useState(false)

	const handleAddNew = e => {
		if (!addingNew) {
			setAddingNew(true)
		}
	}

	return (
		<Stack
			direction='column'
			justifyContent='flex-start'
			alignItems='stretch'
			spacing={1}
		>
			{Object.entries(sourceData).map(([index, source]) => (
				<Source source={source} key={source.slug} />
			))}
			{addingNew && <NewSource setAddingNew={setAddingNew} />}
			<Grid
				container
				justifyContent='flex-end'
			>
				<Grid item>
					<Button
						onClick={handleAddNew}
						disabled={addingNew}
					>
						Add New
					</Button>
				</Grid>
			</Grid>
		</Stack>
	)
}

export default Sources