import {useAuth} from 'providers/auth'

import {
	Forest,
	Merge,
	Cabin,
	TableRows,
	GridOn
} from '@mui/icons-material'

import Sources from 'pages/Sources'
import EntryDataTable from 'pages/EntryDataTable'
import Testing from 'pages/Testing'

const iconSx = {fontSize: '1.4rem'}

export const protectedPaths = [{
	name: 'Home',
	icon: <Cabin sx={iconSx} />,
	path: '/',
	element: <EntryDataTable />
},{
	name: 'Sources',
	icon: <Forest sx={iconSx} />,
	path: '/sources',
	element: <Sources />
// },{
// 	name: 'Testing',
// 	icon: <GridOn sx={iconSx} />,
// 	path: '/testing',
// 	component: <Testing />
}]

export const useProtectedPaths = () => {
	const auth = useAuth()
	// console.log(auth.user)
	return auth?.user?.verifiedToken ? (
		protectedPaths
	) : (
		null
	)
}