import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import {initializeApp} from 'firebase/app'
import {getDatabase} from 'firebase/database'
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check'

const firebaseConfig = {
	apiKey: "AIzaSyA8y7zKP9PnlapLPECZPck4Kp063jEfigU",
	authDomain: "forest-logs.firebaseapp.com",
	databaseURL: "https://forest-logs-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "forest-logs",
	storageBucket: "forest-logs.appspot.com",
	messagingSenderId: "737691331494",
	appId: "1:737691331494:web:e59da735c1cb82ba6bfd67"
}

const app = initializeApp(firebaseConfig)

window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.NODE_ENV === 'development'
initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider('6LcwyqMfAAAAAFZmXAzIloVJTFbDwVSZi55m7Ti0'),
	isTokenAutoRefreshedEnabled: true
})


export const provider = new GoogleAuthProvider()
export const db = getDatabase(app)
export const auth = getAuth(app)