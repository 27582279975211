import {useState, useContext, createContext, useEffect} from 'react'
import {auth, provider} from 'providers/firebase'
import {getAuth, onAuthStateChanged, signInWithPopup, signOut} from 'firebase/auth'
import {api} from 'providers/axios'
import {useLoading} from 'providers/loading'

const verifyToken = token => api({
	method: 'post',
	url: '/client/verifyToken',
	headers: {
		token
	}
})

export const AuthContext = createContext(null)
export const useAuth = () => useContext(AuthContext)

const AuthProvider = props => {
	const [user, setUser] = useState(null)
	const [authError, setAuthError] = useState(null)
	const {setLoading} = useLoading()

	const signUserIn = async () => {
		setAuthError(null)
		setLoading(true)
		try {
			const result = await signInWithPopup(auth, provider)
		} catch (e) {
			setAuthError(e.code)
			console.error(e)
		}
	}

	const signUserOut = async () => {
		try {
			await signOut(auth)
			setUser(null)
		} catch (e) {
			setAuthError((e.code && `[${e.code}] `) + (e.message ?? e))
			console.error(e)
		}
	}

	const verifyUser = async user => {
		if (!user) {
			return
		}

		try {
			const token = await getAuth().currentUser?.getIdToken(true)
			if (!token) {
				throw('no token')
			}

			const verifyTokenResponse = await verifyToken(token)
			user.verifiedToken = verifyTokenResponse.data
			setUser(user)

		} catch (e) {
			signUserOut()
			setAuthError((e.code && `[${e.code}] `) + (e.message ?? e))
			console.error(e)
		}
	}

	useEffect(() => {
		return onAuthStateChanged(auth, async user => {
			setLoading(true)
			try {
				if (user) {
					console.log('onAuthStateChanged', user?.email)
					await verifyUser(user)
				}
			} catch (e) {
				setAuthError((e.code && `[${e.code}] `) + (e.message ?? e))
				console.error(e)
			} finally {
				setLoading(false)
			}
		})
	}, [])


	return (
		<AuthContext.Provider value={{
			signUserIn,
			signUserOut,
			authError,
			user
		}}>
			{props.children}
		</AuthContext.Provider>
	)
}

export default AuthProvider