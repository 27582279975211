import {useState, useEffect, createContext} from 'react'
import {db} from 'providers/firebase'
import {ref, onValue} from 'firebase/database'
import {useAuth} from 'providers/auth'

const DataProvider = props => {
	const auth = useAuth()
	const isUserLoggedIn = Boolean(auth.user)
	const [sourceData, setSourceData] = useState([])

	useEffect(() => {
		if (!isUserLoggedIn) {return}
		const sourceDataRef = ref(db, '/sources')
		onValue(sourceDataRef, snapshot => {
			if (snapshot.val()) {
				setSourceData(Object.values(snapshot.val()))
			}
		})
	}, [isUserLoggedIn])

	return (
		<SourceContext.Provider value={sourceData}>
			{props.children}
		</SourceContext.Provider>
	)
}

export const SourceContext = createContext([])
export default DataProvider