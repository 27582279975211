import {createTheme} from '@mui/material/styles'

/**
 * 1		#2b2f29
 * 2		#31362f
 * 3		#36393f
 * 4		#444b40
 * body		#dddedc
 */

export const theme = createTheme({
	typography: {
		fontFamily: [
			'Overpass',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Helvetica Neue"',
			'sans-serif'
		].join(','),
		fontSize: 12
	},
	palette: {
		mode: 'dark',
		background: {
			paper: '#2b2f29',
			default: '#31362f'
		},
		primary: {
			main: '#eceeec', //'#252A1E',
			border: '#191d17'
		},
		secondary: {
			main: '#2f2929',
			border: '#1c1616'
		},
		text: {
			primary: '#eceeec',
			secondary: '#f2f4f1',
			disabled: '#aaaca9'
		},
		error: {
			main: '#cc5949'
		},
		warning: {
			main: '#df8e5c'
		},
		info: {
			main: '#64aec3'
		},
		success: {
			main: '#658c26'
		}
	}
})

export const scrollbarStyles = {
	"*::-webkit-scrollbar": {
		width: '.6rem',
		height: '.8rem'
	},
	"*::-webkit-scrollbar-thumb": {
		backgroundColor: '#2B2F29'
	}
}