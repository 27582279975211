import {CssBaseline, GlobalStyles} from '@mui/material'
import {ThemeProvider} from '@mui/material/styles'

import UserProvider from 'providers/auth'
import DataProvider from 'providers/DataProvider'
import {theme, scrollbarStyles} from 'providers/theme'
import LoadingProvider, {LoadingHandler} from 'providers/loading'
import PageProvider from 'providers/page'
import {RouteHandler} from 'providers/RouteHandler'

const globalStyles = {
	...scrollbarStyles
}

const App = () => {
	document.title = 'Forest'
	
	return (
		<ThemeProvider theme={theme}>
			<LoadingProvider>
				<PageProvider>
					<UserProvider>
						<DataProvider>
							<GlobalStyles styles={globalStyles}/>
							<CssBaseline />
							<LoadingHandler>
								<RouteHandler />
							</LoadingHandler>
						</DataProvider>
					</UserProvider>
				</PageProvider>
			</LoadingProvider>
		</ThemeProvider>
	)
}

export default App