import {useTheme} from '@mui/material/styles'
import {Box} from '@mui/material'

/**
 * @param heightOffset - can be string, number, or true for default value
 */
export const CenterBox = ({sx, children, heightOffset}) => {
	const theme = useTheme()

	let height
	switch (typeof heightOffset) {
		case 'boolean': {
			height = heightOffset ? `calc(100dvh - ${theme.spacing(8)})` : '100dvh'
			break
		}
		case 'string': {
			height = isNaN(heightOffset) ? heightOffset : `${heightOffset}px`
			break
		}
		case 'number': {
			height = `${heightOffset}px`
			break
		}
		default: {
			height = '100dvh'
			break
		}
	}

	return (
		<Box sx={{
			padding: 2,
			height,
			display: 'flex',
			alignContent: 'space-around',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			gap: 4,
			...sx
		}}>
			<Box>
				{children}
			</Box>
		</Box>
	)
}

export default CenterBox