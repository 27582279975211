import {useEffect} from 'react'
import {useRouteError, useNavigate} from 'react-router-dom'
import {usePage} from 'providers/page'
import {
	Typography,
	Button,
	Box
} from '@mui/material'
import Layout from 'components/Layout'

export default function Error() {
	const {setPageTitle} = usePage()
	const navigate = useNavigate()
	const error = useRouteError()
	
	useEffect(() => {
		setPageTitle('Error')
	}, [])

	return (
		<Layout>
			<Box sx={{
				padding: 2,
				height: '100%',
				display: 'flex',
				alignContent: 'space-around',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				gap: 2
			}}>
				<Typography variant='h1'>Error</Typography>
				<Typography variant='subtitle1'>{(error?.statusText || error?.message) ?? 'Unknown error'}</Typography>
				<Button variant='contained' onClick={() => navigate(-1)}>Go back</Button>
			</Box>
		</Layout>
	)
}