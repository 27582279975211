import {useState, forwardRef} from 'react'
import {useAuth} from 'providers/auth'

import {
	Box,
	IconButton,
	Avatar,
	Menu,
	MenuItem,
	ListItemIcon,
} from '@mui/material'

import {Logout} from '@mui/icons-material'

const AvatarMenuFloat = forwardRef(({anchor, setAnchor}, ref) => {
	const {user, signUserOut} = useAuth()

	const handleAvatarClick = e => setAnchor(e.currentTarget)
	const handleAvatarClose = () => setAnchor(null)

	return (
		<Box ref={ref} sx={{
			position: 'absolute',
			top: 0,
			right: 0,
			zIndex: 10,
			padding: 1
		}}>
			<IconButton
				onClick={handleAvatarClick}
				sx={{
					padding: .5,
					// margin: .7
				}}
			>
				<Avatar
					alt={user?.displayName}
					src={user?.photoURL}
					sx={{
						width: 36,
						height: 36
					}}
				/>
			</IconButton>
			<Menu
				anchorEl={anchor}
				open={!!anchor}
				onClose={handleAvatarClose}
				onClick={handleAvatarClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.3))'
					}
				}}
			>
				<MenuItem onClick={signUserOut}>
					<ListItemIcon>
						<Logout fontSize='small' />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</Box>
	)
})
export default AvatarMenuFloat