import * as React from 'react';
import {useState, useEffect, useCallback, useContext} from 'react'
import {db} from '../providers/firebase'
import {
	ref,
	onValue,
	query,
	limitToLast,
	orderByChild
} from 'firebase/database'
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter'
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
import {atelierLakesideDark} from 'react-syntax-highlighter/dist/esm/styles/hljs'
import {DateTime} from 'luxon'
import {ordinal, capitalise, blank} from 'utils'

import {SourceContext} from 'providers/DataProvider'

import {
	DataGridPro as DataGrid,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from '@mui/x-data-grid-pro'


import {
	Box,
	Tooltip,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from '@mui/material'

import {
	Circle,
	ExpandLess,
	ExpandMore
} from '@mui/icons-material'

SyntaxHighlighter.registerLanguage('json', json)


const ordinalReplacerFunction = (match, weekday, day, rest) => `${weekday}, ${ordinal(day)} ${rest}`

const RecentDate = ({entryDate, dateDiff}) => {
	if (dateDiff.values.seconds > 3600) {
		return entryDate.toLocaleString(DateTime.TIME_24_SIMPLE)
	} else if (dateDiff.values.seconds > 60) {
		return entryDate.toRelative({
			unit: ['hours', 'minutes', 'seconds']
		})
	} else {
		return 'just now'
	}
}

const RenderEntryDate = ({value}) => {
	const [isRecent, setIsRecent] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [now, setNow] = useState(DateTime.now())
	const entryDate = DateTime.fromMillis(value)
	const dateDiff = now.diff(entryDate, ['seconds'])
	
	useEffect(() => {
		setIsRecent(entryDate.hasSame(DateTime.local(), 'day'))
		setIsLoading(false)
	}, [entryDate])

	useEffect(() => {
		const interval = setInterval(() => setNow(DateTime.now()), 1000)
		return () => clearInterval(interval)
	}, [])

	return isLoading ? '...' : (
		<Tooltip placement='top' title={entryDate.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}>
			<span>
				{isRecent ?
					<RecentDate entryDate={entryDate} dateDiff={dateDiff} />
				:
					entryDate.toLocaleString({
						minute: '2-digit',
						hour: 'numeric',
						weekday: 'long',
						day: 'numeric',
						month: 'long',
					}).replace(/([A-Za-z]+),\s(\d{1,2})\s(.*)/, ordinalReplacerFunction)
				}
			</span>
		</Tooltip>
	)
}

const renderLevelIcon = ({value}) => {
	if (!value) {return blank}
	value = value.replace(/^(warn)$/, 'warning')
	return (
		<Tooltip arrow placement='top' title={capitalise(value)}>
			<Circle sx={{
				color: `${value}.main`,
				fontSize: '.75rem',
				verticalAlign: 'middle'
			}}/>
		</Tooltip>
	)
}

const columnOptions = {
	filterable: true,
	hideable: true,
	hideSortIcons: true,
	sortable: false
}


const Testing = () => {
	// const [entryData, setEntryData] = useState([])
	// const [loading, setLoading] = useState(true)
	// const sources = useContext(SourceContext)
	// const [totalEntries, setTotalEntries] = useState(0)

	// const [paginationModel, setPaginationModel] = useState({
	// 	page: 0,
	// 	pageSize: 5,
	// });


	// useEffect(() => {
	// 	onValue(
	// 		query(
	// 			ref(db, '/totalEntries')
	// 		), snapshot => {
	// 			setTotalEntries(snapshot.val())
	// 		}
	// 	)
	// }, [])
	
	// const getSourceName = slug => {
	// 	try {
	// 		return sources.find(source => source.slug === slug).name
	// 	} catch {
	// 		return slug
	// 	}
	// }

	// const renderSource = ({row}) => {
	// 	const {source} = row
	// 	return (
	// 		<Tooltip arrow placement='top' title={<>
	// 			<Box>
	// 				{(source.slug || 'a').toUpperCase()}
	// 			</Box>
	// 			<Box>
	// 				{source.ips ? source.ips.join(',\n') : (source.ip || blank)}
	// 			</Box>
	// 		</>}>
	// 			<span>{getSourceName(source.slug) || source.slug || 'unknown'}</span>
	// 		</Tooltip>
	// 	)
	// }

	// useEffect(() => {
	// 	const entriesDataRef = ref(db, '/entries')
	// 	setLoading(true)
	// 	onValue(
	// 		query(
	// 			entriesDataRef,
	// 			orderByChild('date'),
	// 			limitToLast(paginationModel.pageSize * (paginationModel.page+1))
	// 		), snapshot => {
	// 			if (snapshot.val()) {
	// 				const arr = Object.values(snapshot.val() || {})
	// 				setEntryData(arr)
	// 			}
	// 			setLoading(false)
	// 		}
	// 	)
	// }, [paginationModel])



	// const handlePaginationModelChange = (newPaginationModel) => {
	// 	setPaginationModel(newPaginationModel);
	// };

	// const columns = useCallback(() => ([{
	// 	field: 'level',
	// 	headerName: 'Level',
	// 	renderHeader: () => blank,
	// 	minWidth: 24,
	// 	maxWidth: 24,
	// 	renderCell: renderLevelIcon,
	// 	type: 'singleSelect',
	// 	valueOptions: ['info', 'warn', 'error'],
	// 	...columnOptions
	// },{
	// 	field: 'source',
	// 	headerName: 'Source',
	// 	flex: 1,
	// 	valueGetter: params => params.row.source.slug,
	// 	renderCell: renderSource,
	// 	type: 'singleSelect',
	// 	valueOptions: sources.map(source => ({value: source.slug, label: getSourceName(source.slug)})),
	// 	...columnOptions
	// },{
	// 	field: 'date',
	// 	headerName: 'Date',
	// 	flex: 1.5,
	// 	renderCell: RenderEntryDate,
	// 	...columnOptions
	// },{
	// 	field: 'message',
	// 	headerName: 'Message',
	// 	flex: 3,
	// 	minWidth: 150,
	// 	...columnOptions
	// },{
	// 	...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	// 	headerName: 'Expand'
	// }]), [sources])


	// const rows = useCallback(() => (
	// 	entryData
	// 	.flat()
	// 	.reverse()
	// 	.map((entry, i) => ({
	// 		id: `entry-${i}`,
	// 		date: entry.date,
	// 		level: entry.level,
	// 		message: entry.message,
	// 		source: entry.source,
	// 		data: entry.data,
	// 	}))
	// ), [entryData])

	// const getDetailPanelContent = useCallback(({row}) => {
	// 	return row?.data ? (
	// 		<Table size='small'>
	// 			<TableBody>
	// 				{Object.entries(row.data).map(([key, value]) => (
	// 					<TableRow key={key}>
	// 						<TableCell
	// 							component='td'
	// 							scope='row'
	// 						>
	// 							{decodeURIComponent(key).replace('%2E', '.')}
	// 						</TableCell>
	// 						<TableCell sx={{
	// 							width: '99.9%',
	// 							wordBreak: 'break-all'
	// 						}}>
	// 							{typeof value === 'object' ? (
	// 								<SyntaxHighlighter
	// 									language='json'
	// 									style={atelierLakesideDark}
	// 									wrapLongLines
	// 								>
	// 									{JSON.stringify(value, null, 2)}
	// 								</SyntaxHighlighter>
	// 								) : (
	// 									value
	// 								)
	// 							}
	// 						</TableCell>
	// 					</TableRow>
	// 				))}
	// 			</TableBody>
	// 		</Table>
	// 	) : null
	// }, [entryData])

	// const getDetailPanelHeight = useCallback(() => 'auto', [])

	// return (
	// 	<div style={{ height: '100%', width: '100%' }}>
	// 		<DataGrid
	// 			density='compact'
	// 			rows={rows()}
	// 			columns={columns()}
	// 			autoPageSize
	// 			rowCount={totalEntries}
	// 			// paginationMode="server" 
	// 			onPaginationModelChange={handlePaginationModelChange}
	// 			paginationModel={paginationModel}
	// 			loading={loading}
	// 			getDetailPanelContent={getDetailPanelContent}
	// 			getDetailPanelHeight={getDetailPanelHeight}
	// 			pagination
	// 			components={{
	// 				DetailPanelExpandIcon: ExpandMore,
	// 				DetailPanelCollapseIcon: ExpandLess
	// 			}}
	// 			sx={{
	// 				'& > div > div:nth-of-type(3)': {
	// 					display: 'none'
	// 				},
	// 				'.MuiDataGrid-columnSeparator': {
	// 					display: 'none',
	// 				},
	// 				'.MuiDataGrid-footerContainer': {
	// 					minHeight: '35px',
	// 					height: '35px'
	// 				}
	// 			}}
	// 		/>
	// 	</div>
	// );
	return 'ok'
}

export default Testing