import {useState, useEffect, createContext, useContext} from 'react'

const PageContext = createContext()
export const usePage = () => useContext(PageContext)

const PageProvider = ({children}) => {
	const [pageTitle, setPageTitle] = useState('Forest')
	
	useEffect(() => {
		document.title = pageTitle || 'Forest'
	}, [pageTitle])
	
	return (
		<PageContext.Provider value={{
			pageTitle,
			setPageTitle
		}}>
			{children}
		</PageContext.Provider>
	)
}


export default PageProvider