const now = new Date()
const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const monthsOfTheYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const ordinal = n => n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '')
export const ordinalReplacer = (match, weekday, day, rest) => `${weekday}, ${ordinal(day)} ${rest}`
export const capitalise = s => s && !s.match(/[A-Z]/) ? s.charAt(0).toUpperCase()+s.slice(1).toLowerCase() : s
export const blank = '\u200a'


export const utils = {
	replacers: {
		daymonthyear: {
			regex: /^([a-zA-Z]+, )(\d{1,2})( [a-zA-Z]+)$/,
			f: (orig, day, date, month) => String(day+utils.ordinal(date)+month)
		}
	},
	niceDate: date => `${daysOfTheWeek[date.getUTCDay()]}, ${utils.ordinal(date.getUTCDate())} ${monthsOfTheYear[date.getUTCMonth()]}${date.getFullYear() !== now.getFullYear() ? `, ${date.getFullYear()}` : ''}`,
	makeSlug: s => s.toLowerCase().replace(/^|\b\w/g, x => x.toUpperCase()).replace(/\s/g,''),
}