import {useState, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {
	Box,
	Slide,
	Modal
} from '@mui/material'

import MenuFloat from 'components/Layout/MenuFloat'
import AvatarMenuFloat from 'components/Layout/AvatarMenuFloat'

export const drawerWidth = 240
export const topbarMargin = .5

const FloatingMenus = () => {
	const [mainMenuAnchor, setMainMenuAnchor] = useState(null)
	const [avatarMenuAnchor, setAvatarMenuAnchor] = useState(null)
	const [buttonsShown, setButtonsShown] = useState({
		topLeft: false,
		topRight: false,
		bottomLeft: false,
		bottomRight: false
	})

	useEffect(() => {
		if (!document) {return}
		const activationAreaPx = 64

		const handleMouseMove = e => {
			setButtonsShown(prev => ({
				...prev,
				topLeft: (e.x <= activationAreaPx && e.y <= activationAreaPx),
				topRight: (e.x >= (window.innerWidth - activationAreaPx) && e.y <= activationAreaPx),
				bottomLeft: (e.x <= activationAreaPx && e.y >= (window.innerHeight - activationAreaPx)),
				bottomRight: (e.x >= (window.innerWidth - activationAreaPx) && e.y >= (window.innerHeight - activationAreaPx))
			}))
		}

		document.addEventListener('mousemove', handleMouseMove)
	}, [document])

	return (<>
		<Modal
			open={!!(mainMenuAnchor || avatarMenuAnchor)}
			slotProps={{
				backdrop: {
					sx: {
						backdropFilter: 'blur(4px)'
					}
				}
			}}
		>
			<Box />
		</Modal>
		<Slide
			direction='down'
			in={buttonsShown.topLeft || !!mainMenuAnchor}
		>
			<MenuFloat
				anchor={mainMenuAnchor}
				setAnchor={setMainMenuAnchor}
			/>
		</Slide>
		
		<Slide
			direction='down'
			in={buttonsShown.topRight || !!avatarMenuAnchor}
		>
			<AvatarMenuFloat
				anchor={avatarMenuAnchor}
				setAnchor={setAvatarMenuAnchor}
			/>
		</Slide>
	</>)
}


const Layout = ({children}) => {
	return (
		<Box sx={{height: '100dvh'}}>
			<FloatingMenus />
			<Box
				id='main'
				sx={{
					height: '100%',
					overflow: 'auto',
				}}
			>
				<Box sx={{m: 1}}>
					{children}
					<Outlet />
				</Box>
			</Box>
			
		</Box>
	)
}


export default Layout