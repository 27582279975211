import {useEffect} from 'react'
import {createBrowserRouter, RouterProvider, useNavigate} from 'react-router-dom'
import {useProtectedPaths} from 'providers/protectedPaths'
import {Loading} from 'providers/loading'
import {useAuth} from 'providers/auth'
import Login from 'pages/Login'
import Error from 'pages/Error'
import Layout from 'components/Layout'

const ProtectedRoute = ({children}) => {
	const navigate = useNavigate()
	const {user} = useAuth()

	useEffect(() => {
		if (!user) {
			navigate('/login')
		}
	}, [user])

	return user ? (
		children
	) : (
		<Loading message='Loading...' />
	)
}

export const RouteHandler = () => {
	const protectedPaths = useProtectedPaths()
		
	const router = createBrowserRouter([{
		path: '/login',
		element: <Login />,
		errorElement: <Error />
	},{
		path: '/',
		element: <ProtectedRoute><Layout /></ProtectedRoute>,
		errorElement: <Error />,
		children: protectedPaths && protectedPaths.map(({path, element}) => ({path, element}))
	}])

	
	return (
		<RouterProvider
			router={router}
			fallbackElement={<Loading message='Loading...' />}
		/>
	)
}