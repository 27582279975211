import {useState, createContext, useContext} from 'react'
import {Box, CircularProgress} from '@mui/material'
import {CenterBox} from 'components/CenterBox'

export const LoadingContext = createContext()
export const useLoading = () => useContext(LoadingContext)

export const Loading = props => (
	<CenterBox>
		<CircularProgress
			color='primary'
			disableShrink
			size={32}
			thickness={3}
		/>
		{props.message}
	</CenterBox>
)

export const LoadingPage = props => {
	return <Loading {...props} />
}

const LoadingProvider = ({children}) => {
	const [loading, setLoading] = useState(true)
	
	return (
		<LoadingContext.Provider value={{
			loading,
			setLoading
		}}>
			{children}
		</LoadingContext.Provider>
	)
}

export const LoadingHandler = ({children}) => {
	const {loading} = useLoading()
	return loading ? <Loading /> : children
}

export default LoadingProvider