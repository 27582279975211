import {useState, forwardRef} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'providers/auth'
import {useProtectedPaths} from 'providers/protectedPaths'

import {
	Box,
	IconButton,
	Avatar,
	Menu,
	MenuItem,
	ListItemIcon,
	Divider,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography
} from '@mui/material'

import {
	Menu as MenuIcon,
	Settings,
	Logout
} from '@mui/icons-material'

const NavListItem = ({name, icon, path}) => (
	<ListItem
		key={name}
		disablePadding
	>
		<ListItemButton
			component={Link}
			to={path}
		>
			{icon && <ListItemIcon>
				{icon}
			</ListItemIcon>}
			<ListItemText primary={name} inset={!!icon} />
		</ListItemButton>
	</ListItem>
)

const MenuFloat = forwardRef(({anchor, setAnchor}, ref) => {
	const {signUserOut} = useAuth()
	const protectedPaths = useProtectedPaths()

	const handleClick = e => setAnchor(e.currentTarget)
	const handleClose = () => setAnchor(null)

	return (
		<Box ref={ref} sx={{
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 10,
			padding: 1
		}}>
			<IconButton
				onClick={handleClick}
				sx={{padding: .5}}
			>
				<MenuIcon  />
			</IconButton>
			<Menu
				anchorEl={anchor}
				open={!!anchor}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.3))'
					}
				}}
			>
				{protectedPaths.map(({name, icon, path}, i) => (
					<MenuItem
						key={name}
						component={Link}
						to={path}
						divider={i === protectedPaths.length-1}
					>
						{icon && (
							<ListItemIcon >
								{icon}
							</ListItemIcon>
						)}
						<ListItemText
							primary={name}
							inset={!icon}
						/>
					</MenuItem>
				))}

				<MenuItem onClick={signUserOut}>
					<ListItemIcon >
						<Logout />
					</ListItemIcon>
					<ListItemText
						primary='Logout'
					/>
				</MenuItem>
			</Menu>
		</Box>
	)
})
export default MenuFloat